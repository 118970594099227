/* You can add global styles to this file, and also import other style files */
$first:#464159;
$second:#6c7b95;
$third:#8bbabb;
$fourth:#c7f0db;

$high-lum:#dfdee7;
//complimentary
$complimentary: #545941;
$third-complimentary: #BB8C8B;
  
h1, h2, h3, h4, h5, h6 {
    color: $second;
}

p{
    color: $first;
}

